import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'muse-ui-progress';

Vue.use(VueRouter);

let NPconfig = {
    zIndex: 2000,          // progress z-index
    top: 0,                // position fixed top
    speed: 300,            // progress speed
    color: '#E75296',      // color
    size: 2,               // progress size
    className: ''          // progress custom class
};
Vue.use(NProgress, NPconfig);

const routes = [
    {
        path: '/',
        name: '',
        component: () => import( '../page/index.vue'),
        meta: {index: 0},//meta对象的index用来定义当前路由的层级,由小到大,由低到高
        children: [
            {
                path: '/',
                name: 'main',
                meta: {
                    title: '预约课程',
                    index: 1
                },
                component: () => import( '../page/mainpage.vue')
            },
            {
                path: '/appoint',
                name: 'appoint',
                meta: {
                    title: '预约课程',
                    index: 2
                },
                component: () => import( '../page/appoint.vue')
            }, {
                path: '/record',
                name: 'record',
                meta: {
                    title: '预约记录',
                    index: 1
                },
                component: () => import( '../page/record.vue')
            }, {
                path: '/report',
                name: 'report',
                meta: {
                    title: '我的运动',
                    index: 1
                },
                component: () => import( '../page/report.vue')
            }, {
                path: '/reportLesson',
                name: 'reportLesson',
                meta: {
                    title: '上课统计',
                    index: 1
                },
                component: () => import( '../page/reportLesson.vue')
            }, {
                path: '/reportLessonDetail',
                name: 'reportLessonDetail',
                meta: {
                    title: '上课统计',
                    index: 1
                },
                component: () => import( '../page/reportLessonDetail.vue')
            }, {
                path: '/mine',
                name: 'mine',
                meta: {
                    title: '我的运动',
                    index: 1
                },
                component: () => import( '../page/mine.vue')
            }, {
                path: '/lesson',
                name: 'lesson',
                meta: {
                    title: '课程表',
                    index: 1
                },
                component: () => import( '../page/lesson.vue')
            }, {
                path: '/fitnessRecord',
                name: 'fitnessRecord',
                meta: {
                    title: '体测记录',
                    index: 1
                },
                component: () => import( '../page/fitnessRecord.vue')
            }, {
                path: '/fitnessDetail',
                name: 'fitnessDetail',
                meta: {
                    title: '体测详情',
                    index: 1
                },
                component: () => import( '../page/fitnessDetail.vue')
            }, {
                path: '/lessonRecommendRecord',
                name: 'lessonRecommendRecord',
                meta: {
                    title: '课程推荐记录',
                    index: 1
                },
                component: () => import( '../page/lessonRecommendRecord.vue')
            }, {
                path: '/lessonRecommendDetail',
                name: 'lessonRecommendDetail',
                meta: {
                    title: '课程推荐详情',
                    index: 1
                },
                component: () => import( '../page/lessonRecommendDetail.vue')
            }, {
                path: '/dailyTrainingDetail',
                name: 'dailyTrainingDetail',
                meta: {
                    title: '日常训练',
                    index: 1
                },
                component: () => import( '../page/dailyTrainingDetail.vue')
            }, {
                path: '/dailyTrainingList',
                name: 'dailyTrainingList',
                meta: {
                    title: '日常训练',
                    index: 1
                },
                component: () => import( '../page/dailyTrainingList.vue')
            },
        ]
    },
    {
        path: '/testMemberNavi',
        name: 'testMemberNavi',
        meta: {
            title: '入会测试导航',
            index: 1
        },
        component: () => import( '../page/testMemberNavi.vue')
    },
    {
        path: '/testMembership',
        name: 'testMembership',
        meta: {
            title: '入会测试预约',
            index: 1
        },
        component: () => import( '../page/testMembership.vue')
    },{
        path: '/testMembershipAddress',
        name: 'testMembershipAddress',
        meta: {
            title: '测试场馆地址',
            index: 1
        },
        component: () => import( '../page/testMembershipAddress.vue')
    },{
        path: '/testMembershipQuery',
        name: 'testMembershipQuery',
        meta: {
            title: '入会测试预约查询',
            index: 1
        },
        component: () => import( '../page/testMembershipQuery.vue')
    },{
        path: '/testMembershipDetail',
        name: 'testMembershipDetail',
        meta: {
            title: '预约记录',
            index: 1
        },
        component: () => import( '../page/testMembershipDetail.vue')
    }, {
        path: '/reportLessonDetailWithoutLogin',
        name: 'reportLessonDetailWithoutLogin',
        meta: {
            title: '上课详情',
            index: 1
        },
        component: () => import( '../page/reportLessonDetailWithoutLogin.vue')
    },{
        path: '/membershipCharge',
        name: 'membershipCharge',
        meta: {
            title: '会员充值',
            index: 1
        },
        component: () => import( '../page/membershipCharge.vue')
    },{
        path: '/membershipChargeResult',
        name: 'membershipChargeResult',
        meta: {
            title: '充值结果',
            index: 1
        },
        component: () => import( '../page/membershipChargeResult.vue')
    },{
        path: '/chargeRecord',
        name: 'chargeRecord',
        meta: {
            title: '充值记录',
            index: 1
        },
        component: () => import( '../page/chargeRecord.vue')
    },{
        path: '/chargeNeedsKnow',
        name: 'chargeNeedsKnow',
        meta: {
            title: '充值说明',
            index: 1
        },
        component: () => import( '../page/chargeNeedsKnow.vue')
    },{
        path: '/login',
        name: 'login',
        meta: {
            title: '登陆',
            index: 1
        },
        component: () => import( '../page/login.vue')
        // component: () => import( '../page/404.vue')
    }, {
        path: '/test',
        name: 'test',
        meta: {
            title: 'test',
            index: 1
        },
        component: () => import( '../page/test.vue')
    }, {
        path: '/404',
        name: '404',
        meta: {
            title: '404',
            index: 1
        },
        component: () => import( '../page/404.vue')
    }, {
        path: '*',
        name: 'notFound',
        meta: {
            title: '404',
            index: 3
        },
        component: () => import( '../page/notFound.vue')
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

// 路由守卫
router.beforeEach((to, from, next) => {
    NProgress.start();
    const isLogin = localStorage.token ? true : false;
    //路由白名单
    if (to.path == '/login' || to.path == '/testMemberNavi' ||
        to.path == '/testMembership' || to.path == '/testMembershipAddress'|| to.path == '/testMembershipQuery' ||
        to.path == '/testMembershipDetail' || to.path == '/reportLessonDetailWithoutLogin' ||
        to.path == '/membershipCharge' || to.path == '/membershipChargeResult' ||
        to.path == '/chargeNeedsKnow'
    ) {
        next();
    } else {
        //如果token存在，就正常跳转，如果不存在，则说明未登陆，则跳转到'login'
        isLogin ? next() : next("/login");
        // next();
    }
});

router.afterEach(() => {
    NProgress.done()
})

export default router
